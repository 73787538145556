var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('pro-menu-layout',[_c('pro-deck',{attrs:{"title":_vm.$t('CustMenu.Com.Action.ViewContractManagement'),"cards":_vm.items,"statuses":_vm.statuses,"filter-by-status":"","is-loading":_vm.load.loading},on:{"update:isLoading":function($event){return _vm.$set(_vm.load, "loading", $event)},"update:is-loading":function($event){return _vm.$set(_vm.load, "loading", $event)}},scopedSlots:_vm._u([{key:"titleAction",fn:function(){return [_c('pro-button',{attrs:{"label":"","icon":"PROSmart-Refresh","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.handleRefresh()}}})]},proxy:true},{key:"default",fn:function(ref){
var card = ref.card;
var status = ref.status;
return [_c('pro-deck-card',{attrs:{"expandable":"","title":_vm.$t(card.contractNo),"status":status ? status.label : '',"statusColor":status ? status.color : ''},scopedSlots:_vm._u([{key:"action",fn:function(){return [(card.completedAgreementFileId)?_c('pro-button',{attrs:{"label":"System.Button.DownloadSignedDocument","icon":"PROSmart-download"},on:{"click":function($event){$event.stopPropagation();return _vm.handleDownload(card.completedAgreementFileId)}}}):_vm._e()]},proxy:true}],null,true)},[[_c('div',{staticClass:"row"},[(card.cancelMessage)?_c('el-alert',{attrs:{"title":card.cancelMessage,"type":"error","closable":false}}):_vm._e()],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-auto"},[_c('pro-key-value-table',{attrs:{"rows":_vm.contractInfoRows,"data":card}})],1)]),_c('div',{staticClass:"row"},[_c('pro-report',{attrs:{"row-key":"sequence","value":card.signeeList,"columns":_vm.signeeListColumns,"simple-table-mode":""}})],1),_c('q-separator'),_c('pro-expansion-item',{attrs:{"expandable":true,"default-opened":false},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('q-item-section',[_c('div',{staticClass:"row no-wrap items-center q-col-gutter-md"},[_c('div',{staticClass:"text-h4 text-primary",staticStyle:{"white-space":"normal","word-break":"break-all"}},[_vm._v(" "+_vm._s(_vm.$t("Form.Field.EventHistory"))+" ")])])])]},proxy:true}],null,true)},[[(card.event.length === 0)?_c('div',{staticClass:"text-center q-pa-md"},[_vm._v(" "+_vm._s(_vm.$t("Form.Message.Error.NoRecordFound"))+" ")]):_vm._e(),_c('q-stepper',{attrs:{"value":"1","vertical":"","color":"primary","animated":""}},_vm._l((card.event),function(event){return _c('q-step',{key:event.id,attrs:{"flat":"","active-icon":_vm.getIconAndColor(event.type).icon,"active-color":_vm.getIconAndColor(event.type).color,"name":"1","title":card.userNameMapping[event.actingUserEmail]
                      ? card.userNameMapping[event.actingUserEmail] +
                        ' - ' +
                        event.actingUserEmail
                      : event.actingUserEmail,"caption":_vm.$proSmart.common.getFormattedDate(new Date(event.date))}},[_vm._v(" "+_vm._s(event.description)+" ")])}),1)]],2),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12",staticStyle:{"text-align":"center"}})])]],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }