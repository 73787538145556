<template>
  <pro-menu-layout>
    <pro-deck
      :title="$t('CustMenu.Com.Action.ViewContractManagement')"
      :cards="items"
      :statuses="statuses"
      filter-by-status
      :is-loading.sync="load.loading"
    >
      <template v-slot:titleAction>
        <pro-button
          label=""
          icon="PROSmart-Refresh"
          @click.stop="handleRefresh()"
          color="primary"
        />
      </template>

      <template v-slot:default="{ card, status }">
        <pro-deck-card
          expandable
          :title="$t(card.contractNo)"
          :status="status ? status.label : ''"
          :statusColor="status ? status.color : ''"
        >
          <template v-slot:action>
            <pro-button
              v-if="card.completedAgreementFileId"
              label="System.Button.DownloadSignedDocument"
              icon="PROSmart-download"
              @click.stop="handleDownload(card.completedAgreementFileId)"
            />
          </template>
          <template>
            <div class="row">
              <el-alert
                v-if="card.cancelMessage"
                :title="card.cancelMessage"
                type="error"
                :closable="false"
              />
            </div>
            <div class="row">
              <div class="col-auto">
                <pro-key-value-table :rows="contractInfoRows" :data="card" />
              </div>
            </div>

            <div class="row">
              <pro-report
                row-key="sequence"
                :value="card.signeeList"
                :columns="signeeListColumns"
                simple-table-mode
              ></pro-report>
            </div>

            <q-separator />

            <pro-expansion-item :expandable="true" :default-opened="false">
              <template #header>
                <q-item-section>
                  <div class="row no-wrap items-center q-col-gutter-md">
                    <div
                      class="text-h4 text-primary"
                      style="white-space: normal; word-break: break-all"
                    >
                      {{ $t("Form.Field.EventHistory") }}
                    </div>
                  </div>
                </q-item-section>
              </template>
              <template>
                <div v-if="card.event.length === 0" class="text-center q-pa-md">
                  {{ $t("Form.Message.Error.NoRecordFound") }}
                </div>

                <q-stepper value="1" vertical color="primary" animated>
                  <q-step
                    flat
                    v-for="event in card.event"
                    :key="event.id"
                    :active-icon="getIconAndColor(event.type).icon"
                    :active-color="getIconAndColor(event.type).color"
                    name="1"
                    :title="
                      card.userNameMapping[event.actingUserEmail]
                        ? card.userNameMapping[event.actingUserEmail] +
                          ' - ' +
                          event.actingUserEmail
                        : event.actingUserEmail
                    "
                    :caption="
                      $proSmart.common.getFormattedDate(new Date(event.date))
                    "
                  >
                    {{ event.description }}
                  </q-step>
                </q-stepper>
              </template>
            </pro-expansion-item>
            <div class="row">
              <div class="col-12" style="text-align: center"></div>
            </div>
          </template>
        </pro-deck-card>
      </template>
    </pro-deck>
  </pro-menu-layout>
</template>

<script>
import ProMenuLayout from "@/components/PROSmart/Layout/ProMenuLayout";
import ProDeck from "@/components/PROSmart/Deck/ProDeck.vue";
import ProDeckCard from "@/components/PROSmart/Deck/ProDeckCard.vue";
import ProKeyValueTable from "@/components/PROSmart/ProKeyValueTable.vue";
import ProReport from "@/components/PROSmart/Report/ProReport.vue";
import ProExpansionItem from "@/components/PROSmart/QuasarWrapperComponent/ProExpansionItem.vue";
import ProButton from "@/components/PROSmart/ProButton.vue";

export default {
  name: "contractManagement",
  components: {
    ProButton,
    ProExpansionItem,
    ProReport,
    ProKeyValueTable,
    ProDeckCard,
    ProDeck,
    ProMenuLayout,
  },
  data() {
    return {
      load: { loading: false, error: false },
      contractInfoRows: [
        {
          label: this.$t("Form.Field.AwardedContractorName"),
          field: "awardedTendererName",
        },
        {
          label: this.$t("Form.Field.AwardDate"),
          field: "awardDate",
        },
        {
          label: this.$t("Form.Field.ContractSumHKD"),
          field: "contractSum",
        },
        {
          label: this.$t("Form.Field.CommencementDate"),
          field: "commencementDate",
        },
        {
          label: this.$t("Form.Field.ContractStartDate"),
          field: "contractStartDate",
        },
        {
          label: this.$t("Form.Field.ContractEndDate"),
          field: "contractEndDate",
        },
        {
          label: this.$t("Form.Field.AgreementName"),
          field: "agreementName",
        },
        {
          label: this.$t("Form.Field.Message"),
          field: "agreementMessage",
        },
        {
          label: this.$t("Form.Field.AgreementId"),
          field: "agreementId",
        },
        {
          label: this.$t("Form.Field.SigneeList"),
          field: "",
        },
      ],
      statuses: [
        {
          key: "Active",
          label: this.getRes("CustMenu.Col.Active"),
          color: "purple",
        },
        {
          key: "Completed",
          label: this.getRes("CustMenu.Col.Completed"),
          color: "green",
        },
        {
          key: "Disabled",
          label: this.getRes("CustMenu.Col.Cancel"),
          color: "orange",
        },
      ],
      signeeListColumns: [
        {
          name: "sequence",
          i18NLabel: "Form.Col.Seq",
          field: "sequence",
          searchable: false,
          type: "number",
          sortable: false,
          align: "left",
          style: "width: 10px",
        },
        {
          name: "type",
          i18NLabel: "Form.Col.SigneeType",
          field: "type",
          searchable: false,
          type: "string",
          sortable: false,
          align: "left",
          style: "width: 20px",
        },
        {
          name: "state",
          i18NLabel: "Form.Col.State",
          field: "state",
          searchable: false,
          type: "chip",
          list: [
            {
              label: this.getRes("Document.Status.Signed"),
              value: "Signed",
            },
          ],
          sortable: false,
          align: "left",
          style: "width: 20px",
        },
        {
          name: "name",
          i18NLabel: "Form.Field.Name",
          field: "name",
          searchable: false,
          type: "string",
          sortable: false,
          align: "left",
        },
        {
          name: "email",
          i18NLabel: "Form.Field.Email",
          field: "email",
          searchable: false,
          type: "string",
          sortable: false,
          align: "left",
        },
        {
          name: "remark",
          i18NLabel: "Form.Field.Remark",
          field: "remark",
          searchable: false,
          type: "string",
          sortable: false,
          align: "left",
        },
      ],
      items: [],
    };
  },
  methods: {
    getIconAndColor(action) {
      switch (action) {
        case "ACTION_COMPLETED":
          return { color: "green", icon: "PROSmart-Check" };
        case "ACTION_DELEGATED":
        case "EMAIL_BOUNCED":
          return { color: "red", icon: "PROSmart-Alert" };
        case "RECALLED":
          return { color: "orange", icon: "PROSmart-Remove" };
      }

      return { color: "grey", icon: "null" };
    },
    handleDownload(fileId) {
      this.$proSmart.download.downloadFile(this, this.$route.params.id, fileId);
    },
    handleRefresh() {
      this.load.loading = true;
      this.items = [];
      this.$proSmart.contractManagement
        .updateTenderContractLogAndStatus(this, this.$route.params.id)
        .then(() => {
          this.loadContact();
        });
    },
    loadContact() {
      this.load.loading = true;
      this.items = [];

      this.$proSmart.contractManagement
        .getTenderContractInfo(this, this.$route.params.id)
        .then((b) => {
          this.items = b.map((c) => {
            let userNameMapping = {};

            for (let signee of c.signeeList.list) {
              userNameMapping[signee.email[0].email] = signee.name;
            }

            return {
              contractNo: c.contractNo,
              status: c.status,
              awardedTendererName: c.awardedTendererName,
              awardDate: this.$proSmart.common.getFormattedDateOnly(
                new Date(c.awardDate)
              ),
              contractSum: this.$proSmart.common.getCurrencyFormatted(
                c.contractSum
              ),
              commencementDate: this.$proSmart.common.getFormattedDateOnly(
                new Date(c.commencementDate)
              ),
              contractStartDate: this.$proSmart.common.getFormattedDateOnly(
                new Date(c.contractStartDate)
              ),
              contractEndDate: this.$proSmart.common.getFormattedDateOnly(
                new Date(c.contractEndDate)
              ),
              agreementId: c.agreementId,
              agreementName: c.agreementName,
              agreementMessage: c.agreementMessage,
              signeeList: c.signeeList.list.map((s) => {
                return {
                  sequence: s.sequence,
                  type: s.type,
                  name: s.name,
                  email: s.email[0].email,
                  remark: s.remark,
                  state:
                    c.eventList.find(
                      (d) =>
                        d.type === "ACTION_COMPLETED" &&
                        d.actingUserEmail === s.email[0].email
                    ) != null
                      ? "Signed"
                      : null,
                };
              }),
              event: c.eventList,
              userNameMapping,
              completedAgreementFileId: c.completedAgreementFileId,
              cancelMessage: c.cancelMessage,
            };
          });
          this.load.loading = false;
        });
    },
  },
  mounted() {
    this.loadContact();
  },
};
</script>

<style scoped>
.q-stepper {
  box-shadow: none;
}
</style>
